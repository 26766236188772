@tailwind base;
@tailwind components;
@tailwind utilities;

/* SegoeUI font */
@font-face {
  font-family: "SegoeUI";
  src: url("../assets/fonts/SegoeUI/SegoeUI.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "SegoeUI";
  src: url("../assets/fonts/SegoeUI/SegoeUISemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "SegoeUI";
  src: url("../assets/fonts/SegoeUI/SegoeUIBold.ttf");
  font-weight: 700;
}

/* Jost font */
@font-face {
  font-family: "Jost";
  src: url("../assets/fonts/Jost/Jost-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Jost";
  src: url("../assets/fonts/Jost/Jost-Medium.ttf");
  font-weight: 500;
}

/* Chakra_Petch font */
@font-face {
  font-family: "ChakraPetch";
  src: url("../assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "ChakraPetch";
  src: url("../assets/fonts/Chakra_Petch/ChakraPetch-Medium.ttf");
  font-weight: 500;
}

/* Hype */
@font-face {
  font-family: "Hype";
  src: url("../assets/fonts/Hype/HYPE.ttf");
  font-weight: 400;
}

/* IBMPlexSans */
@font-face {
  font-family: "IBMPlexSans";
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
  font-weight: 400;
}

@layer components {
  .main-container {
    @apply container mx-auto;
  }
  .input-field {
    @apply text-formSubSize bg-transparent text-white border w-full border-[#CED4DA] rounded px-3 py-[0.438rem] focus:border-[#E9CD3B] outline-none placeholder-[rgba(250,250,250,0.6)];
  }
  .input-field-error {
    @apply border-errorBorderColor;
  }
  .input-label {
    @apply text-formSubSize text-white mb-[0.5rem] block;
  }
  .search-input-field {
    @apply text-xs font-dropdownFont bg-transparent text-[rgba(250,250,250,0.45)] border w-full border-[rgba(250,250,250,0.45)] rounded pl-8 pr-3 py-[0.29rem] outline-none;
  }
  .main-button {
    @apply bg-mainColor text-black text-formSubSize py-1 px-4 min-w-[11.75rem] rounded cursor-pointer min-h-[28px] flex items-center justify-center font-semibold;
  }
  .itemErrorMessage {
    @apply text-formSubSize absolute top-16 left-0 text-errorTextColor pt-1;
  }
  .white-button {
    @apply font-dropdownFont font-bold uppercase tracking-wider bottom-2 border-2 border-white rounded py-1 px-3 min-w-[9rem]  w-fit flex justify-center text-white;
  }
  .black-button {
    @apply font-dropdownFont font-bold uppercase tracking-wider bottom-2 border-2 border-[#090909] rounded py-1 px-3 min-w-[9rem]  w-fit flex justify-center text-[#090909];
  }
}

body {
  background: #1f1f1f;
}

.line-one {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line-two {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.line-three {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.passwordIcon {
  background-image: url("../public/images/icons/eye.svg");
  width: 22px;
  height: 22px;
  position: absolute;
  top: 34px;
  right: 7px;
  cursor: pointer;
  z-index: 8;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.passwordIcon.show {
  background-image: url("../public/images/icons/eye-open.svg");
}

/* custom checkbox */
.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.containerCheckbox .checkMark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.containerCheckbox:hover input ~ .checkMark {
  background-color: transparent;
}
.containerCheckbox input:checked ~ .checkMark {
  background-color: #e9cd3b;
  border-color: #e9cd3b;
}
.containerCheckbox .checkMark.small {
  width: 15px;
  height: 15px;
  top: 4px;
  border-radius: 2px;
}
.containerCheckbox .checkMark:after {
  content: "";
  position: absolute;
  display: none;
}
.containerCheckbox input:checked ~ .checkMark:after {
  display: block;
}
.containerCheckbox .checkMark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 11px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.containerCheckbox .checkMark.small:after {
  left: 4px;
  top: 2px;
  width: 6px;
  height: 8px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* custom checkbox end */

/* custom checkbox for dropdown*/
.containerCheckboxDropdown {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.containerCheckboxDropdown input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.containerCheckboxDropdown .checkMarkDropdown {
  position: absolute;
  top: 16px;
  left: 13px;
  width: 15px;
  height: 15px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.containerCheckboxDropdown:hover input ~ .checkMarkDropdown {
  background-color: transparent;
}
.containerCheckboxDropdown input:checked ~ .checkMarkDropdown {
  background-color: #e9cd3b;
  border-color: #e9cd3b;
}
.containerCheckboxDropdown:hover input ~ .checkboxText {
  background: #2e2e2e;
}

.containerCheckboxDropdown .checkboxText {
  display: block;
  padding: 17px 14px 17px 50px;
  background: #2e2e2e;
}
.containerCheckboxDropdown input:checked ~ .checkboxText:after {
  content: "";
  background: linear-gradient(
    90deg,
    #e9cd3b -3.18%,
    rgba(233, 205, 59, 0) 111.27%
  );
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.containerCheckboxDropdown .checkMarkDropdown:after {
  content: "";
  position: absolute;
  display: none;
}
.containerCheckboxDropdown input:checked ~ .checkMarkDropdown:after {
  display: block;
}
.containerCheckboxDropdown .checkMarkDropdown:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.containerCheckboxDropdown.noCheckbox .checkMarkDropdown {
  display: none;
}

.containerCheckboxDropdown.noCheckbox:hover .checkboxText:after {
  content: "";
  background: linear-gradient(
    90deg,
    #e9cd3b -3.18%,
    rgba(233, 205, 59, 0) 111.27%
  );
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.containerCheckboxDropdown.noCheckbox .checkboxText {
  padding: 14px 14px 14px 19px;
}
/* custom checkbox for dropdown end */

/* custom radio button for dropdown*/
.containerRadioDropdown {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.containerRadioDropdown input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containerRadioDropdown .checkMarkDropdown {
  position: absolute;
  top: 16px;
  left: 19px;
  width: 15px;
  height: 15px;
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.containerRadioDropdown:hover input ~ .checkMarkDropdown {
  background-color: transparent;
}
.containerRadioDropdown input:checked ~ .checkMarkDropdown {
  background-color: #e9cd3b;
  border-color: #e9cd3b;
}
.containerRadioDropdown:hover input ~ .RadioText {
  background: #2e2e2e;
}

.containerRadioDropdown .RadioText {
  display: block;
  padding: 17px 14px 17px 44px;
  background: #2e2e2e;
}
.containerRadioDropdown input:checked ~ .RadioText:after {
  content: "";
  background: linear-gradient(
    90deg,
    #e9cd3b -3.18%,
    rgba(233, 205, 59, 0) 111.27%
  );
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.containerRadioDropdown .checkMarkDropdown:after {
  content: "";
  position: absolute;
  display: none;
}
.containerRadioDropdown input:checked ~ .checkMarkDropdown:after {
  display: block;
}
.containerRadioDropdown .checkMarkDropdown:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* custom radio button for dropdown end */

/* popup show */
.mainPopup.active {
  visibility: visible;
  opacity: 1;
  z-index: 999999;
}
.mainPopup {
  position: fixed;
  background-color: transform;
  z-index: 99999999;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* home team slider*/
.homeTeamSlider .slick-track {
  margin: 0;
}
.homeTeamSlider .slick-arrow.slick-disabled {
  display: none !important;
}
.homeTeamSlider .slick-arrow.slick-prev {
  width: 5px;
  height: 10px;
  background-image: url("../public/images/icons/left-yellow-arrow.svg");
  width: 5px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  left: -15px;
}
.homeTeamSlider .slick-arrow.slick-next {
  width: 5px;
  height: 10px;
  background-image: url("../public/images/icons/right-yellow-arrow.svg");
  width: 5px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  right: -15px;
}
.homeTeamSlider .slick-arrow:before {
  content: "";
}
/* home team end */

/*  saved Searches Slider */
.savedSearchesSlider .slick-track {
  margin: 0;
}
.savedSearchesSlider .slick-arrow.slick-disabled {
  display: none !important;
}
.savedSearchesSlider .slick-arrow.slick-prev {
  width: 5px;
  height: 10px;
  background-image: url("../public/images/icons/left-arrow-white.svg.svg");
  width: 5px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  left: -15px;
}
.savedSearchesSlider .slick-arrow.slick-next {
  width: 5px;
  height: 10px;
  background-image: url("../public/images/icons/right-arrow-white.svg");
  width: 5px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  right: -15px;
}
.savedSearchesSlider .slick-arrow:before {
  content: "";
}
/* saved Searches Slider end */

/*  month Slider */
.monthSlider .slick-track {
  margin: 0;
}
.monthSlider .slick-arrow.slick-disabled {
  display: none !important;
}
.monthSlider .slick-arrow.slick-prev {
  width: 50px;
  height: 50px;
  background-image: url("../public/images/icons/left-arrow-white.svg.svg");
  background-repeat: no-repeat;
  background-size: 5px 10px;
  /* left: -15px; */
  background-position: center;
  left: -40px;
  border-radius: 100%;
}
.monthSlider .slick-arrow.slick-next {
  width: 50px;
  height: 50px;
  background-image: url("../public/images/icons/right-arrow-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 5px 10px;
  /* right: -15px; */
  background-position: center;
  right: -40px;
  border-radius: 100%;
}
.monthSlider .slick-arrow:before {
  content: "";
}
/* month Slider end */

/* home calendar */
.homeCalendar .react-datepicker__month-container {
  background: #2e2e2e;
  border-radius: 10px;
  color: #ffffff;
  font-family: "ChakraPetch";
  min-width: 317px;
  min-height: 287px;
}
.homeCalendar .react-datepicker {
  background-color: transparent !important;
  border: none !important;
}
.homeCalendar .react-datepicker__header.react-datepicker-year-header {
  background: #2e2e2e;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  padding-top: 22px;
  font-family: "IBMPlexSans";
  color: #e9cd3b;
  border: none;
}
.homeCalendar .react-datepicker__month-container .react-datepicker__month {
  padding: 1rem 0;
  text-transform: uppercase;
}
.homeCalendar .react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.homeCalendar .react-datepicker__month-container .react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 13px;
  line-height: 18px;
  padding: 0.2rem;
  letter-spacing: 0.05em;
  margin: 8px 10px !important;
  padding: 8px 17px;
  width: 74px;
  height: 34px;
}
.homeCalendar
  .react-datepicker__month-container
  .react-datepicker__month-text:hover {
  background-color: #e9cd3b;
  color: #000000 !important;
}
.homeCalendar .react-datepicker__month-text {
  cursor: pointer;
  text-align: center;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 500 !important;
}
.homeCalendar .react-datepicker__month-text.react-datepicker__month--disabled {
  cursor: default;
  opacity: 0.35;
}
.homeCalendar .react-datepicker__month-text--keyboard-selected {
  background: #e9cd3b !important;
  color: #000000 !important;
}
.homeCalendar .react-datepicker__navigation-icon {
  top: 15px !important;
}
.homeCalendar .react-datepicker__navigation-icon--previous::before {
  border-color: #e9cd3b !important;
}
.homeCalendar .react-datepicker__navigation-icon--next::before {
  border-color: #e9cd3b !important;
}
.homeCalendar .react-datepicker__navigation {
  height: 47px !important;
  width: 90px !important;
}
.openHomeCalendar {
  border: 1px solid #e9cd3b;
  border-radius: 4px;
  font-family: "ChakraPetch";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  padding: 5px 9px;
  padding-right: 24px;
  color: #e9cd3b;
  min-width: 124px;
  margin-left: 10px;
  position: relative;
}
.openHomeCalendar::after {
  content: "";
  width: 10px;
  height: 6px;
  background-image: url("../public/images/icons/arrow-down-yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 12px;
}
/* home calendar end */

/* step1 calendar */
.stepCalendar .react-datepicker__month-container {
  background: #1f1f1f;
  border-radius: 10px;
  color: #e9cd3b !important;
  font-family: "ChakraPetch";
  min-width: 317px;
  min-height: 287px;
}
.stepCalendar .react-datepicker {
  background-color: transparent !important;
  border: none !important;
}
.stepCalendar .react-datepicker__header.react-datepicker-year-header {
  background: #1f1f1f;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  padding-top: 22px;
  font-family: "IBMPlexSans";
  color: #e9cd3b;
  border: none;

  display: none;
}
.stepCalendar .react-datepicker__month-container .react-datepicker__month {
  padding: 1rem 0;
  text-transform: uppercase;
}
.stepCalendar .react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
  display: none;
}
.stepCalendar .react-datepicker__month-container .react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 17px;
  line-height: 18px;
  padding: 16px 17px;
  width: 117px;
  height: 54px;
  letter-spacing: 0.05em;
  margin: 13px 15px !important;
}
.stepCalendar
  .react-datepicker__month-container
  .react-datepicker__month-text:hover {
  background-color: #e9cd3b;
  color: #000000 !important;
}
.stepCalendar .react-datepicker__month-text {
  cursor: pointer;
  text-align: center;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 500 !important;
}
.stepCalendar .react-datepicker__month-text.react-datepicker__month--disabled {
  cursor: default;
  opacity: 0.35;
  color: #e9cd3b !important;
}
.stepCalendar
  .react-datepicker__month-text--keyboard-selected.react-datepicker__month--disabled {
  color: #000000 !important;
}
.stepCalendar .react-datepicker__month-text--keyboard-selected {
  background: #e9cd3b !important;
  color: #000000 !important;
}
.stepCalendar .react-datepicker__navigation {
  display: none !important;
}
.stepCalendar .react-datepicker__navigation-icon {
  top: 15px !important;
}
.stepCalendar .react-datepicker__navigation-icon--previous::before {
  border-color: #e9cd3b !important;
}
.stepCalendar .react-datepicker__navigation-icon--next::before {
  border-color: #e9cd3b !important;
}
.stepCalendar .react-datepicker__navigation {
  height: 47px !important;
  width: 90px !important;
}
.openstepCalendar {
  border: 1px solid #e9cd3b;
  border-radius: 4px;
  font-family: "ChakraPetch";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  padding: 5px 9px;
  padding-right: 24px;
  color: #e9cd3b;
  min-width: 124px;
  margin-left: 5px;
  position: relative;
}
.openstepCalendar::after {
  content: "";
  width: 10px;
  height: 6px;
  background-image: url("../public/images/icons/arrow-down-yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 12px;
}
/* step1 calendar end */

/* step2 radio button  */
.stepRadioButton {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.stepRadioButton input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.stepRadioButton .RadioText {
  display: block;
  text-transform: uppercase;
  font-family: "ChakraPetch";
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  padding: 23px 5px;
  display: flex;
  justify-content: center;
  letter-spacing: 0.05em;
  color: #e9cd3b;
}
.stepRadioButton .image {
  padding: 18px;
  background: #1f1f1f;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
}
.stepRadioButton input:checked ~ .image {
  background: #e9cd3b;
}
.stepRadioButton .image img {
  width: 151px;
  height: 147px;
  object-fit: cover;
  border-radius: 5px;
}

/* step2 radio button end */

/* step3 radio button  */
.stepCategory {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.stepCategory input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.stepCategory .RadioText {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.05em;
  padding: 8px 21px;
}
.stepCategory .image {
  padding: 18px;
  background: #1f1f1f;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
}
.stepCategory input:hover ~ .RadioText::before,
.stepCategory input:checked ~ .RadioText::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    #e9cd3b -3.18%,
    rgba(233, 205, 59, 0) 98.82%
  );
  opacity: 0.35;
}
.stepCategory input:hover ~ .RadioText::after,
.stepCategory input:checked ~ .RadioText::after {
  width: 3px;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #e9cd3b;
}

/* question */
.stepQuestion {
  display: block;
  position: relative;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
}
.stepQuestion input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.stepQuestion .RadioText {
  font-family: "ChakraPetch";
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.05em;
  padding: 12px 23px;
  border: 1px solid rgba(191, 191, 191, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  color: rgba(225, 225, 225, 0.8);
  /*width: 650px;*/
}
.stepQuestion .image {
  padding: 18px;
  background: #1f1f1f;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
}
.stepQuestion input:hover ~ .RadioText,
.stepQuestion input:checked ~ .RadioText {
  background: #e9cd3b;
  color: rgba(0, 0, 0, 0.8);
}

/* checkbox */
.formCheckbox {
  display: block;
  position: relative;
  cursor: pointer;
}
.formCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.formCheckbox .checkMarkDropdown {
  position: absolute;
  top: 1px;
  left: 0px;
  width: 22px;
  height: 22px;
  border: 1px solid #e9cd3b;
  border-radius: 4px;
}
.formCheckbox:hover input ~ .checkMarkDropdown {
  background-color: transparent;
}
.formCheckbox input:checked ~ .checkMarkDropdown {
  background-color: #e9cd3b;
  border-color: #e9cd3b;
}

.formCheckbox .checkboxText {
  display: block;
  padding: 3px 14px 3px 33px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.formCheckbox .checkMarkDropdown:after {
  content: "";
  position: absolute;
  display: none;
}
.formCheckbox input:checked ~ .checkMarkDropdown:after {
  display: block;
}
.formCheckbox .checkMarkDropdown:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* step3 radio button end */
/* custom scrolbar */
/* width */
.customScroll::-webkit-scrollbar {
  width: 2px;
  background: #2e2e2e;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: transparant;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.7);
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* custom scrolbar end */

/* search form with steps */
.searchTop {
  display: flex;
}
.searchTop_item {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;*/
}
.searchTop_item .line {
  width: 100%;
  height: 2px;
  background: #e9cd3b;
  position: absolute;
  left: 0px;
  top: 15px;
  z-index: 11;
}
/* .searchTop_item .line:before {
  content: "";
  width: 118px;
  height: 2px;
  background: #e9cd3b;
  /* position: absolute;
  left: 15px;
  top: 15px;  
} */
.searchTop_item .info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1f1f1f;
  position: relative;
  z-index: 12;
  min-width: 60px;
}
.searchTop_item .info .text {
  font-family: "Jost";
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #e9cd3b;
  margin-top: 10px;
}
.searchTop_item:nth-child(2),
.searchTop_item:nth-child(3) {
  padding-left: 125px;
}
.searchTop_item .number {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9cd3b;
  font-family: "ChakraPetch";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
}
.searchTop_item.disable {
  opacity: 0.35;
}
/* search form with steps end */

/* calendar with dropdown */
.mainCalendar .react-datepicker__month-container {
  background: #2e2e2e;
  border-radius: 10px;
  color: #ffffff;
  font-family: "ChakraPetch";
  min-width: 317px;
  min-height: 287px;
}
.mainCalendar .react-datepicker {
  background-color: transparent !important;
  border: none !important;
}
.mainCalendar .react-datepicker__header.react-datepicker-year-header {
  background: #2e2e2e;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  padding-top: 22px;
  font-family: "IBMPlexSans";
  color: #e9cd3b;
  border: none;
}
.mainCalendar .react-datepicker__month-container .react-datepicker__month {
  padding: 1rem 0;
  text-transform: uppercase;
}
.mainCalendar .react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.mainCalendar .react-datepicker__month-container .react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 13px;
  line-height: 18px;
  padding: 0.2rem;
  letter-spacing: 0.05em;
  margin: 8px 10px !important;
  padding: 8px 17px;
  width: 74px;
  height: 34px;
}
.mainCalendar
  .react-datepicker__month-container
  .react-datepicker__month-text:hover {
  background-color: #e9cd3b;
  color: #000000 !important;
}
.mainCalendar .react-datepicker__month-text {
  cursor: pointer;
  text-align: center;
  border: 2px solid #e9cd3b;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 500 !important;
}
.mainCalendar .react-datepicker__month-text.react-datepicker__month--disabled {
  cursor: default;
  opacity: 0.35;
}
.mainCalendar .react-datepicker__month-text--keyboard-selected {
  background: #e9cd3b !important;
  color: #000000 !important;
}
.mainCalendar .react-datepicker__navigation-icon {
  top: 15px !important;
}
.mainCalendar .react-datepicker__navigation-icon--previous::before {
  border-color: #e9cd3b !important;
}
.mainCalendar .react-datepicker__navigation-icon--next::before {
  border-color: #e9cd3b !important;
}
.mainCalendar .react-datepicker__navigation {
  height: 47px !important;
  width: 90px !important;
}
.openDropdown {
  border: 1px solid #e9cd3b;
  border-radius: 4px;
  font-family: "ChakraPetch";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  padding: 5px 9px;
  padding-right: 24px;
  color: #e9cd3b;
  min-width: 124px;
  position: relative;
  min-height: 30px;
}
.openDropdown.empty {
  border-width: 2px;
}
.openDropdown::after {
  content: "";
  width: 10px;
  height: 6px;
  background-image: url("../public/images/icons/arrow-down-yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 12px;
}
/* calendar with dropdown end */

/* persona patgination */
.personaPagination .pagination .next,
.personaPagination .pagination .previous {
  display: block;
}
.personaPagination .pagination li,
.personaPagination .pagination .disabled .pageChange {
  display: none;
}
.personaPagination .pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.personaPagination .pagination {
  position: absolute;
  top: 0;
}
.personaImageShadow {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
}

/* persona answer slider */
.personaAnswerSlider .slick-track {
  margin: 0;
}
.personaAnswerSlider .slick-arrow.slick-disabled {
  display: none !important;
}
.personaAnswerSlider .slick-arrow.slick-prev {
  width: 9px;
  height: 15px;
  background-image: url("../public/images/icons/left-yellow-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 0px;
  z-index: 20;
}
.personaAnswerSlider .slick-arrow.slick-next {
  width: 9px;
  height: 15px;
  background-image: url("../public/images/icons/right-yellow-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: 0px;
  z-index: 20;
}
.personaAnswerSlider .slick-arrow:before {
  content: "";
}
/* persona answer slider end */

.personaArrow {
  background: #1f1f1f;
  padding-left: 25px;
  z-index: 10;
}

/* persona answer slider */
.personaMostSearchedSliderArrow .slick-track {
  margin: 0;
}
.personaMostSearchedSliderArrow .slick-arrow.slick-disabled {
  display: none !important;
}
.personaMostSearchedSliderArrow .slick-arrow.slick-prev {
  width: 9px;
  height: 15px;
  background-image: url("../public/images/persona/black-arrow-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: -25px;
  z-index: 20;
}
.personaMostSearchedSliderArrow .slick-arrow.slick-next {
  width: 9px;
  height: 15px;
  background-image: url("../public/images/persona/black-arrow-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: -25px;
  z-index: 20;
}
.personaMostSearchedSliderArrow .slick-arrow:before {
  content: "";
}
/* persona answer slider end */
.searchResult:hover:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #e9cd3b;
  border-radius: 10px;
  left: 0;
  top: 0;
}
.searchResult:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    #e9cd3b -3.18%,
    rgba(233, 205, 59, 0) 111.27%
  );
  opacity: 0.35;
}

/* width */
.topScrollResult::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.topScrollResult::-webkit-scrollbar-track {
  background: transparant;
}

/* Handle */
.topScrollResult::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.topScrollResult::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* close popup */
.closePopup {
  background: rgba(35, 35, 35, 0.55);
  backdrop-filter: blur(10px);
  border-radius: 3px;
}

.mediaPopup {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.mediaPopup.open {
  visibility: visible;
  opacity: 1;
  z-index: 999999;
}

/* audio animation */
.box {
  height: 34px;
  width: 34px;
  background-color: #e9cd3b;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.bigBox {
  height: 50px;
  width: 50px;
  border-radius: 8px;
}
.bigBox__line {
  max-height: 28px !important;
}
.bigBox__line::before {
  transform: translate(-9px, 0) !important;
}
.bigBox__line::after {
  transform: translate(9px, 0) !important;
}

.smallBox {
  height: 26px;
  width: 26px;
}
.smallBox__line {
  max-height: 12px !important;
  margin-bottom: 5px !important;
  width: 2.5px !important;
}
.smallBox__line::before {
  transform: translate(-5px, 0) !important;
}
.smallBox__line::after {
  transform: translate(5px, 0) !important;
}

.mediumBox {
  height: 44px;
  width: 44px;
}
.mediumBox__line {
  max-height: 22px !important;
  margin-bottom: 5px !important;
  width: 3.5px !important;
}
.mediumBox__line::before {
  transform: translate(-10px, 0) !important;
}
.mediumBox__line::after {
  transform: translate(10px, 0) !important;
}

.box__line {
  width: 3px;
  max-height: 18px;
  margin-bottom: 8px;
  background-color: #1f1f1f;
  z-index: 9;
  position: relative;
  animation-name: bar1;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.box__line:before {
  content: "";
  position: absolute;
  transform: translate(-7px, 0);
  animation-name: bar2;
  height: 100%;
  width: 100%;
  background-color: #1f1f1f;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  bottom: 0;
}
.box__line:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  transform: translate(7px, 0);
  animation-name: bar3;
  z-index: 999;
  background-color: #1f1f1f;
  bottom: 0;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes bar1 {
  0% {
    height: 60%;
  }

  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}
@keyframes bar2 {
  0% {
    height: 50%;
  }

  60% {
    height: 10%;
  }
  100% {
    height: 100%;
  }
}

@keyframes bar3 {
  0% {
    height: 80%;
  }
  30% {
    height: 50%;
  }
  100% {
    height: 80%;
  }
}
/* audio animation end */

/* single persona swiper arrows */
.personaSwiper .swiper-button-prev {
  width: 34px;
  height: 14px;
  background: #1f1f1f;
  position: absolute;
  background-image: url("../public/images/persona/arrow-right.svg");
  background-repeat: no-repeat;
  background-position: right;
  z-index: 14;
  bottom: 14px;
  transform: rotate(180deg);
  cursor: pointer;
}

.personaSwiper .swiper-button-next {
  width: 34px;
  height: 14px;
  background: #1f1f1f;
  position: absolute;
  background-image: url("../public/images/persona/arrow-right.svg");
  background-repeat: no-repeat;
  background-position: right;
  right: 0px;
  bottom: 14px;
  z-index: 14;
  cursor: pointer;
}
.personaSwiper .swiper-button-disabled {
  display: none;
}
/* single persona swiper arrows end */
